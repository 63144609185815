import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit, } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
var QuestionarioCasaComunitariaComponent = /** @class */ (function () {
    //questionarioConfiguracao: QuestionarioConfiguracao;
    function QuestionarioCasaComunitariaComponent(
    //private missaoConfiguracaoService: MissaoConfiguracaoService,
    usuarioService, imagemService, pessoaService, casacomunitariaService, membroService, elementoGrupoDominioService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, route, router, swtAlert2Service, arquivoService, sanitizer) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casacomunitariaService = casacomunitariaService;
        this.membroService = membroService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.idsPPs = [25];
        this.idsPDs = [30, 31, 108, 204, 205];
        this.idsD1s = [24];
        this.idsPostulantes = [22, 23];
        this.idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];
        this.selectedFile = null;
        this.errorMessage = "";
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.questionariosCasaComunitaria = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    QuestionarioCasaComunitariaComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCasaComunitariaAssociada = false;
        this.buscaMembros = {
            casacomunitariaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
        this.buscaQuestionarioFormando = {
            questionarioId: null,
            casaCelulaId: null,
            pessoaId: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    };
    QuestionarioCasaComunitariaComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    QuestionarioCasaComunitariaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
                        this.carregarNivelFormacao();
                        return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadorComunitario()];
                    case 3:
                        _a.sent();
                        if (!(this.formadorComunitario != undefined &&
                            this.formadorComunitario.casaCelulaId != null)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.carregarCasaComunitaria()];
                    case 4:
                        _a.sent();
                        this.buscaMembros = {
                            casacomunitariaId: this.casacomunitaria.id,
                            pageNumber: 0,
                            quantityOfElements: 4,
                        };
                        this.buscaQuestionarioFormando = {
                            questionarioId: this.questionarioId,
                            casaCasaComunitariaId: this.casacomunitaria.id,
                            pessoaId: null,
                        };
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        this.hasCasaComunitariaAssociada = false;
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarArquivoPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    /*carregarQuestionarioConfiguracao(questionarioId: number) {
      const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
      this.missaoConfiguracaoService
        .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
          missaoId,
          questionarioId
        )
        .subscribe(
          (questionarioConfiguracao) =>
            (this.questionarioConfiguracao = questionarioConfiguracao)
        );
    }*/
    QuestionarioCasaComunitariaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarFormadorComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.formadorComunitarioService.buscarCasaComunitariaPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        this.formadorComunitario = response;
                        if (!(this.formadorComunitario.pessoaImagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadorComunitario.pessoaImagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadorComunitario);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.casacomunitariaService.find(this.formadorComunitario.casaCelulaId)];
                    case 1:
                        response = _a.sent();
                        this.casacomunitaria = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarQuestionarioCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorCasaComunitariaId(this.buscaQuestionarioFormando)];
                    case 1:
                        response = _a.sent();
                        this.questionariosCasaComunitaria = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        console.log(e_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarMembrosCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var idsBusca, response, response, _loop_1, this_1, i, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 10, , 11]);
                        idsBusca = [];
                        switch (this.questionarioId) {
                            case 4:
                                idsBusca = this.idsPPs;
                                break;
                            case 5:
                                idsBusca = this.idsPDs;
                                break;
                            case 6:
                                idsBusca = this.idsPostulantes;
                                break;
                            case 7:
                                idsBusca = this.idsD1s;
                                break;
                            case 8:
                                idsBusca = this.idsRenovacao;
                                break;
                            default:
                                idsBusca = null;
                                break;
                        }
                        if (!(idsBusca != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.membroService.listarMembrosCasaComunitariaPorNivel(this.casacomunitaria.id, idsBusca)];
                    case 1:
                        response = _a.sent();
                        this.membrosCasaComunitaria = response.content;
                        this.totalDePaginasMembros = response.totalPages;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.membroService.listarTodosMembrosCasaComunitaria(this.casacomunitaria.id)];
                    case 3:
                        response = _a.sent();
                        this.membrosCasaComunitaria = response.content;
                        this.totalDePaginasMembros = response.totalPages;
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.carregarQuestionarioCasaComunitaria()];
                    case 5:
                        _a.sent();
                        _loop_1 = function (i) {
                            var response, pessoa, blogImage, nivelFormativoMembro, questionario;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this_1.pessoaService.buscaPorId(this_1.membrosCasaComunitaria[i].pessoaId)];
                                    case 1:
                                        response = _a.sent();
                                        pessoa = response.entity;
                                        this_1.membrosCasaComunitaria[i].pessoaNome = pessoa.nome + " " + pessoa.sobrenome;
                                        this_1.membrosCasaComunitaria[i].pessoaImagemId = pessoa.imagemId;
                                        if (!(this_1.membrosCasaComunitaria[i].pessoaImagemId != null)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this_1.getPessoaImagem(this_1.membrosCasaComunitaria[i].pessoaImagemId)];
                                    case 2:
                                        blogImage = _a.sent();
                                        this_1.imagemService.createImage(blogImage, this_1.membrosCasaComunitaria[i]);
                                        _a.label = 3;
                                    case 3:
                                        nivelFormativoMembro = this_1.niveisFormacao.find(function (obj) { return obj.id === _this.membrosCasaComunitaria[i].nivelFormativoId; });
                                        this_1.membrosCasaComunitaria[i].nivelFormativoNome = nivelFormativoMembro
                                            ? nivelFormativoMembro.nome
                                            : null;
                                        questionario = this_1.questionariosCasaComunitaria.filter(function (questionarioFormando) {
                                            return questionarioFormando.id === _this.membrosCasaComunitaria[i].id;
                                        });
                                        if (questionario.length > 0) {
                                            this_1.membrosCasaComunitaria[i].statusQuestionario =
                                                questionario[0].status_questionario;
                                            this_1.membrosCasaComunitaria[i].idQuestionarioFormando =
                                                questionario[0].questionarioFormandoId;
                                            this_1.membrosCasaComunitaria[i].idQuestionario = questionario[0].questionarioId;
                                            this_1.membrosCasaComunitaria[i].statusParecer = questionario[0].status_parecer;
                                            this_1.membrosCasaComunitaria[i].idParecer = questionario[0].parecerId;
                                            this_1.membrosCasaComunitaria[i].acompanhadoPor = questionario[0].acompanhadoPor;
                                            this_1.membrosCasaComunitaria[i].acompanhadoPorNome =
                                                questionario[0].acompanhadoPorNome;
                                            this_1.membrosCasaComunitaria[i].arquivo = questionario[0].arquivo;
                                            this_1.membrosCasaComunitaria[i].dataEnvioParecer =
                                                questionario[0].dataEnvioParecer;
                                            this_1.membrosCasaComunitaria[i].dataEnvioQuestionario =
                                                questionario[0].dataEnvioQuestionario;
                                            this_1.membrosCasaComunitaria[i].missaoAtualId = questionario[0].missaoAtualId;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 6;
                    case 6:
                        if (!(i < this.membrosCasaComunitaria.length)) return [3 /*break*/, 9];
                        return [5 /*yield**/, _loop_1(i)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 6];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    /*async carregarQuestionario(pessoaId: number): Promise<any> {
      try {
        let response = await this.questionarioFormandoService.buscarPorPessoaId(
          pessoaId
        );
        if (response != undefined && response != null) {
          return response;
        }
      } catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
      }
    }
  
    async carregarParecer(idQuestionario: number): Promise<any> {
      try {
        let response =
          await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
            idQuestionario
          );
        return response;
      } catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
      }
    }*/
    QuestionarioCasaComunitariaComponent.prototype.consultarCaminhoFormativo = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response_1, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(formando.pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (!(response != null && response != undefined)) return [3 /*break*/, 2];
                        this.caminhoFormativo = response;
                        return [3 /*break*/, 4];
                    case 2:
                        this.caminhoFormativo = {};
                        this.caminhoFormativo.pessoaId = formando.pessoaId;
                        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                        this.caminhoFormativo.ano_formativo_id = 2;
                        this.caminhoFormativo.nome_formador_comunitario =
                            this.formadorComunitario.pessoaNome;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 3:
                        response_1 = _a.sent();
                        this.caminhoFormativo = response_1;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.cadastrarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responsePorPessoa, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.consultarCaminhoFormativo(formando)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, this.questionarioId)];
                    case 2:
                        responsePorPessoa = _a.sent();
                        if (!(responsePorPessoa != undefined &&
                            responsePorPessoa != null &&
                            responsePorPessoa.questionarioId == this.questionarioId)) return [3 /*break*/, 3];
                        this.questionarioFormando = responsePorPessoa;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        return [3 /*break*/, 5];
                    case 3:
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = this.questionarioId;
                        this.questionarioFormando.pessoaId = formando.pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 4:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                            formando.idQuestionarioFormando = this.questionarioFormando.id;
                            //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                        }
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.cadastrarVinculoParecerCAL = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 3;
                        this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer-default/" +
                                    this.parecerResponsavel.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.consultarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        if (!(formando.idQuestionarioFormando != undefined &&
                            formando.idQuestionarioFormando != null)) return [3 /*break*/, 3];
                        if (!(formando.idParecer != undefined && formando.idParecer != null)) return [3 /*break*/, 1];
                        this.router.navigate([
                            "/secured/parecer-cal-answer-default/" +
                                formando.idParecer,
                        ]);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(formando.idQuestionarioFormando != undefined ||
                            formando.idQuestionarioFormando == null)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.carregarNivelFormacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "NIVEL_FORMACAO",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.niveisFormacao = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.configModalUploadQuestionario = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 1];
                        this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QuestionarioCasaComunitariaComponent.prototype.onFileSelected = function (event) {
        var file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFile = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
        this.checkFormUploadValidity();
    };
    QuestionarioCasaComunitariaComponent.prototype.onUpload = function () {
        var _this = this;
        if (this.selectedFile && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: function (data) {
                    _this.swtAlert2Service.successAlert(data);
                    _this.carregarMembrosCasaComunitaria();
                    $("#modal-upload-questionario").modal("hide");
                },
                error: function (error) {
                    _this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: function () {
                    _this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
        }
        else {
            if (this.selectedFile && this.pedidoFormandoId) {
                this.errorMessage = "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage = "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
            }
        }
    };
    QuestionarioCasaComunitariaComponent.prototype.onTipoPedidoChange = function () {
        this.checkFormUploadValidity();
    };
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    QuestionarioCasaComunitariaComponent.prototype.checkFormUploadValidity = function () {
        this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
    };
    QuestionarioCasaComunitariaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    };
    return QuestionarioCasaComunitariaComponent;
}());
export { QuestionarioCasaComunitariaComponent };
