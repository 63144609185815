import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { environment } from "src/environments/environment";
import { CacheService } from "src/app/service/cache.service";

@Injectable()
export class MembroComunidadeService extends GenericService<MembroComunidade> {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    private cacheService: CacheService
  ) {
    super(http, router);
    this.setEntityType("membrocomunidade");
  }

  buscarPorCelula(busca: any): Promise<any> {
    return this.http
      .post<any>(
        environment.moduloFormacao.urlModuloFormacao +
          "membrocomunidade/porcelula",
        JSON.stringify(busca),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorCasaComunitaria(busca: any): Promise<any> {
    return this.http
      .post<any>(
        environment.moduloFormacao.urlModuloFormacao +
          "membrocomunidade/porcasacomunitaria",
        JSON.stringify(busca),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  listarMembrosCelula(id: number): Promise<any> {
    const cacheKey = "listarMembrosCelula-" + JSON.stringify(id);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/celula/porid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }

  listarMembrosCasaComunitaria(id: number): Promise<any> {
    return this.http
      .get<any>(`${this.apiURL}membrocomunidade/porcasacomunitaria/${id}`, {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  listarTodosMembrosCelula(id: number): Promise<any> {
    const cacheKey = "listarTodosMembrosCelula-" + JSON.stringify(id);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/celula/allporid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }

  listarMembrosCelulaPorNivel(
    id: number,
    nivelFormativoIds: number[],
    page: number
  ): Promise<any> {
    const cacheKey =
      "listarMembrosCelulaPorNivel-" +
      JSON.stringify(id) +
      JSON.stringify(nivelFormativoIds) +
      "?page=" +
      page;

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/celula/poridenivel/${id}/n/${nivelFormativoIds}`,
        { headers: this.getHeaders() }
      )
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }

  listarMembrosCasaComunitariaPorNivel(
    id: number,
    nivelFormativoIds: number[]
  ): Promise<any> {
    const cacheKey =
      "listarMembrosCasaComunitariaPorNivel-" +
      JSON.stringify(id) +
      JSON.stringify(nivelFormativoIds);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/casacomunitaria/poridenivel/${id}/n/${nivelFormativoIds}`,
        { headers: this.getHeaders() }
      )
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }

  listarTodosMembrosCasaComunitaria(id: number): Promise<any> {
    const cacheKey = "listarTodosMembrosCelula-" + JSON.stringify(id);

    // Verifica se os dados já estão no cache
    if (this.cacheService.has(cacheKey)) {
      return Promise.resolve(this.cacheService.get(cacheKey));
    }

    // Caso não tenha no cache, faz a requisição HTTP
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}membrocomunidade/casacomunitaria/allporid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise()
      .then((response) => {
        this.cacheService.set(cacheKey, response); // Armazena no cache
        return response;
      });
  }

  vincularMembroCelula(request: any): Promise<any> {
    return this.http
      .post<any>(
        this.apiURL + "/vincularmembrocelula",
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  vincularMembroCasaComunitaria(request: any): Promise<any> {
    return this.http
      .post<any>(
        this.apiURL + "/vincularmembrocasacomunitaria",
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  desvincularMembroCelula(request: any): Promise<any> {
    return this.http
      .post<any>(
        this.apiURL + "/desvincularmembrocelula",
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  desvincularMembroCasaComunitaria(request: any): Promise<any> {
    return this.http
      .post<any>(
        this.apiURL + "/desvincularmembrocasacomunitaria",
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  detalhesMembroCelula(request: any): Promise<any> {
    return this.http
      .post<any>(this.apiURL + "/detalhesmembro", JSON.stringify(request), {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  detalhesMembroCasaComunitaria(request: any): Promise<any> {
    return this.http
      .post<any>(this.apiURL + "/detalhesmembro", JSON.stringify(request), {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  buscarPorPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(`${this.apiURL}/porpessoa/${id}`, {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  aniversariantesCelula(celulaId: number): Promise<any> {
    return this.http
      .get<any>(`${this.apiURL}/celula/aniversariantes/${celulaId}`, {
        headers: this.getHeaders(),
      })
      .toPromise();
  }

  aniversariantesCasaComunitaria(casaComunitariaId: number): Promise<any> {
    return this.http
      .get<any>(
        `${this.apiURL}/casacomunitaria/aniversariantes/${casaComunitariaId}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}
