import { NgModule } from "@angular/core";
import { SecuredComponent } from "./secured.component";
import { SecuredRoutingModule } from "./secured-routing.module";
import { HeaderModule } from "./header/header.module";
import { FooterModule } from "./footer/footer.module";
import { CommonModule } from "@angular/common";
import { TableComponent } from "src/app/core/table/table.component";
import { Select2Component } from "src/app/core/select2/select2.component";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { RouterManagerService } from "./routermanager.service";
import { LeftSidebarMissionarioModule } from "./left-sidebar-missionario/left-sidebar-missionario.module";
import { LeftSidebarFormadorComunitarioModule } from "./left-sidebar-formador-comunitario/left-sidebar-formador-comunitario.module";
import { LeftSidebarCoordColegiadoModule } from "./left-sidebar-coord-colegiado/left-sidebar-coord-colegiado.module";
import { LeftSidebarAcessoNegadoModule } from "./left-sidebar-acesso-negado/left-sidebar-acesso-negado.module";
import { LeftSidebarFormadorAssistenteModule } from "./left-sidebar-formador-assistente/left-sidebar-formador-assistente.module";
import { LeftSidebarFormadorComunitarioCasaModule } from "./left-sidebar-formador-comunitario-casa/left-sidebar-formador-comunitario-casa.module";
import { LeftSidebarConselhoModule } from "./left-sidebar-conselho/left-sidebar-conselho.module";

@NgModule({
  declarations: [SecuredComponent, TableComponent, Select2Component],
  imports: [
    CommonModule,
    SecuredRoutingModule,
    HeaderModule,
    LeftSidebarMissionarioModule,
    LeftSidebarFormadorComunitarioModule,
    LeftSidebarFormadorComunitarioCasaModule,
    LeftSidebarFormadorAssistenteModule,
    LeftSidebarCoordColegiadoModule,
    LeftSidebarConselhoModule,
    LeftSidebarAcessoNegadoModule,
    FooterModule,
  ],
  exports: [
    SecuredComponent,
    HeaderModule,
    LeftSidebarMissionarioModule,
    LeftSidebarFormadorComunitarioModule,
    LeftSidebarCoordColegiadoModule,
    FooterModule,
    TableComponent,
    Select2Component,
  ],
  providers: [SwtAlert2Service, RouterManagerService],
})
export class SecuredModule {}
