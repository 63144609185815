import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SecuredModule } from "../../layout/secured.module";
import { RouterModule } from "@angular/router";
import { CelulaComponent } from "./celula.component";
import { CelulasComponent } from "./celulas.component";
import { CelulaEditComponent } from "./celula.edit.component";
import { CelulaRoutingModule } from "./celula-routing.module";
import { CelulaService } from "./service/celula.service";
import { GerenciamentoFcCelulaComponent } from "./telas-formador-comunitario/gerenciamento.fc.celula.component";
import { GerenciamentoCelulaComponent } from "./gerenciamento.celula.component";
import { MembroComunidadeService } from "../../service/membro.comunidade.service";
import { FormadorComunitarioService } from "../../service/formador.comunitario.service";
import { FormadorAssistenteService } from "../../service/formador.assist.service";
import { CriarLinkCadastroRequestBuilder } from "src/app/builder/criarlinkcadastro.request.builder";
import { CelulaBuilder } from "src/app/builder/celula.builder";
import { ListagemPessoaInfoModalModule } from "../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module";
import { CentroEvangelizacaoService } from "../../service/centroevangelizacao.service";
import { CelulasCoordenadorColegiadoComponent } from "./telas-coordenador-colegiado/celulas.coordenadorcolegiado.component";
import { CelulaManageComponent } from "./tela-gerenciar/celula.manage.component";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { GerenciamentoFaCelulaComponent } from "./telas-formdor-assistente/gerenciamento.fa.celula.component";

@NgModule({
  declarations: [
    CelulaComponent,
    CelulasComponent,
    CelulaEditComponent,
    GerenciamentoCelulaComponent,
    GerenciamentoFcCelulaComponent,
    GerenciamentoFaCelulaComponent,
    CelulasCoordenadorColegiadoComponent,
    CelulaManageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CelulaRoutingModule,
    FormsModule,
    SecuredModule,
    ListagemPessoaInfoModalModule,
  ],
  providers: [
    CelulaService,
    MembroComunidadeService,
    MembroComunidadeBuilder,
    FormadorComunitarioService,
    FormadorAssistenteService,
    CriarLinkCadastroRequestBuilder,
    CelulaBuilder,
    CentroEvangelizacaoService,
  ],
})
export class CelulaModule {}
