import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionariosComponent } from './questionarios/questionarios.component';
import { QuestionarioAgradecimentoComponent } from './missionario/questionario-agradecimento.component';
import { QuestionarioD2AnswerComponent } from './missionario/questionariod2-answer.component';
import { QuestionarioD2ViewAnswerComponent } from './missionario/questionariod2-view-answer.component';
import { QuestionarioCALAnswerComponent } from './missionario/questionario-cal-answer.component';
import { QuestionarioCALViewAnswerComponent } from './missionario/questionario-cal-view-answer.component';
import { QuestionarioComponent } from './questionario.component';
import { QuestionarioCelulaComponent } from './celula/questionario-celula.component';
import { QuestionarioCasaComunitariaComponent } from './casa-comunitaria/questionario-casa-comunitaria.component';

const pessoaRoutes: Routes = [
    {
        path: 'secured/questionariod2-answer/:id',
        component: QuestionarioD2AnswerComponent
    },
    {
        path: 'secured/questionariocal-answer/:id',
        component: QuestionarioCALAnswerComponent
    },
    {
        path: 'secured/questionarios',
        component: QuestionariosComponent
    },
    {
        path: 'secured/questionario-agradecimento',
        component: QuestionarioAgradecimentoComponent
    },
    {
        path: 'secured/questionariod2-view-answer/:id',
        component: QuestionarioD2ViewAnswerComponent
    },
    {
        path: 'secured/questionariocal-view-answer/:id',
        component: QuestionarioCALViewAnswerComponent
    },
    {
        path: 'secured/celula/questionarios',
        component: QuestionarioComponent
    },
    {
        path: 'secured/casacomunitaria/questionarios',
        component: QuestionarioComponent
    },
    {
        path: 'secured/questionario/celula/:id',
        component: QuestionarioCelulaComponent
    },
    {
        path: 'secured/questionario/casacomunitaria/:id',
        component: QuestionarioCasaComunitariaComponent
    }
] 

@NgModule({
    imports: [
        RouterModule.forChild(pessoaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class QuestionarioRoutingModule {
    
}