<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>PARECER DO FORMADOR COMUNITÁRIO - {{questionarioNome}}</h3>
                    </div>
                </div>
            </div>
            <div class="callout callout-info">
                <h5>Atenção!</h5>
                Após o confirmação dos <strong><em>Dados Pessoais Formando</em></strong> solicitados abaixo, os botões
                <strong><em>Salvar</em></strong> e <strong><em>Próximo</em></strong> serão liberados.
                <br>
                Salve o formulário e, após o salvamento, clique em <strong><em>Próximo</em></strong> para acessar a aba
                <strong><em>Questionário</em></strong>.
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill"
                                href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab"
                                aria-selected="true">Dados Pessoais Formando</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill"
                                href="#questionario-tab-content" role="tab" aria-controls="questionario-tab"
                                aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel"
                            aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar()" novalidate>
                                <div class="row">
                                    <div *ngIf="(formando.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2"
                                                                [src]="formando.thumbnail == null ? 'assets/images/ppl.png' : formando.thumbnail"
                                                                alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="(formando.id != undefined) ? 'col-sm-8 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_nome">Nome</label>
                                                                <br>
                                                                {{formando.nome}} {{formando.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_missao_atual">Missão
                                                                    Atual</label>
                                                                <select disabled class="form-control"
                                                                    id="nformando_missao_atual"
                                                                    [(ngModel)]="formando.vinculoMissao.missaoAtualId"
                                                                    name="nformando_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma
                                                                        missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo"
                                                                        [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label><br>
                                                                {{pessoaFormadorPessoalSelecionado?.nome}}&nbsp;{{pessoaFormadorPessoalSelecionado?.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador
                                                                    Comunitário</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.nome_formador_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacompanhadoPor">Quem acompanha o
                                                                    formando
                                                                    comunitariamente.</label>
                                                                <select class="form-control" id="nacompanhadoPor"
                                                                    name="nacompanhadoPor"
                                                                    [(ngModel)]="caminhoFormativoFormando.acompanhado_por"
                                                                    [disabled]="caminhoFormativoFormando.acompanhado_por">
                                                                    <option value="1" i18n>Formador Comunitário</option>
                                                                    <option value="2" i18n>Formador Assistente</option>
                                                                    <option value="3" i18n>Coordenador do Colegiado
                                                                    </option>
                                                                    <option value="4" i18n>Responsável Local</option>
                                                                    <option value="5" i18n>Outro Missionário</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="submit" class="btn"
                                                        [ngClass]="formando.id != undefined ? 'btn-primary' : 'btn-success'"
                                                        [disabled]="!dadosPessoaisForm.valid" i18n>Salvar</button>&nbsp;
                                                    <button type="button" class="btn btn-default"
                                                        (click)="activateTab('questionario-tab-content')"
                                                        *ngIf="formando.id != undefined">Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel"
                            aria-labelledby="questionario-tab">
                            <form *ngIf="formando.id != undefined" #questionarioForm="ngForm"
                                (ngSubmit)="salvarQuestionario()" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <h4 class="card-title">A partir de agora, responda com
                                            sinceridade e transparência tendo em mente todo o contexto de vida atual do
                                            seu formando,
                                            após tê-lo escutado em acompanhamento comunitário e minimamente conhecer sua
                                            realidade de vida. <br>
                                            É importante estar em espírito de oração e intercessão por cada irmão
                                            buscando o discernimento
                                            a partir da escuta de Deus e do irmão, evitando julgamentos e afirmações
                                            deterministas acerca do irmão.
                                            É importante ainda a sensibilidade quanto a temas e situações delicadas.
                                            Se hover algo que venha a expor demais o irmão, cite a área em questão mas
                                            <strong><em>não relate a situação por escrito</em></strong> e busque o RL e
                                            Coordenador do Colegiado para partilhar diretamente.
                                        </h4>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Li e entendi a orientação dada no parágrafo
                                                acima:</label>&nbsp;&nbsp;&nbsp;
                                            <label>
                                                <input [(ngModel)]="resposta39.resposta" type="radio" id="resposta39"
                                                    name="resposta39" value="1">
                                                &nbsp;Sim
                                            </label>&nbsp;&nbsp;
                                            <label>
                                                <input [(ngModel)]="resposta39.resposta" type="radio" id="resposta39"
                                                    name="resposta39" value="0">
                                                &nbsp;Não
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="descricao">Nº de acompanhamentos comunitários deste ano:</label>
                                            <input type="text" [(ngModel)]="resposta36.resposta" type="text"
                                                id="resposta36" name="resposta36" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="descricao">Ano de ingresso no D1</label>
                                            <input type="text" [(ngModel)]="resposta37.resposta" type="text"
                                                id="resposta37" name="resposta37" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="descricao">Ano de Primeiras Promessas</label>
                                            <input type="text" [(ngModel)]="resposta38.resposta" type="text"
                                                id="resposta38" name="resposta38" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="descricao">Desde quando esse irmão é seu formando?</label>
                                            <input type="text" [(ngModel)]="resposta40.resposta" type="text"
                                                id="resposta40" name="resposta40" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">1. Levando em conta as suas impressões acerca da vivência
                                            do formando e suas partilhas no acompanhamento, partilhe abaixo,
                                            considerando a vivência do mesmo em todo seu ambiente como: célula,
                                            ministérios, no ambiente familiar e profissional. Além disso, leve em
                                            consideração, ao responder cada pergunta, 3 critérios principais acerca da
                                            vivência do formando:
                                            Você percebe que ele tem uma vivência coerente?
                                            No seu ponto de vista, ele se comunga com a mentalidade do Carisma no
                                            respectivo aspecto?
                                            Você acredita que ele se identifica com o aspecto proposto?
                                            Como, na sua percepção, o seu formando tem vivido a:</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Pobreza:</label>
                                            <input type="text" [(ngModel)]="resposta41.resposta" type="text"
                                                id="resposta41" name="resposta41" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Obediência:</label>
                                            <input type="text" [(ngModel)]="resposta42.resposta" type="text"
                                                id="resposta42" name="resposta42" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Castidade:</label>
                                            <input type="text" [(ngModel)]="resposta43.resposta" type="text"
                                                id="resposta43" name="resposta43" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Contemplação (Considere a vivência deste irmão na
                                                Oração pessoal, Estudo Bíblio, Vigília, Terço, Missa diária,
                                                Confissão):</label>
                                            <input type="text" [(ngModel)]="resposta44.resposta" type="text"
                                                id="resposta44" name="resposta44" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Unidade:</label>
                                            <input type="text" [(ngModel)]="resposta45.resposta" type="text"
                                                id="resposta45" name="resposta45" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Evangelização:</label>
                                            <input type="text" [(ngModel)]="resposta46.resposta" type="text"
                                                id="resposta46" name="resposta46" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">2. Como está a participação deste formando em
                                                compromissos comunitários como: Reciclagem, Retiro de Célula, CA/CV,
                                                Retiro da Grande Comunidade, lazer da célula:</label>
                                            <input type="text" [(ngModel)]="resposta47.resposta" type="text"
                                                id="resposta47" name="resposta47" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">3. Seu formando já tem definido seu Estado de Vida?
                                                Qual? Como você acredita que ele tem vivido essa dimensão? Se não
                                                definiu, está trilhando um caminho de discernimento?</label>
                                            <input type="text" [(ngModel)]="resposta48.resposta" type="text"
                                                id="resposta48" name="resposta48" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">4. Há algo no histórico vocacional do seu formando
                                                que, na sua opinião, atrapalhe a sua caminhada neste próximo passo?
                                                Comente:
                                            </label>
                                            <input type="text" [(ngModel)]="resposta49.resposta" type="text"
                                                id="resposta49" name="resposta49" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5. Você identifica fragilidades nas áreas de Saúde
                                                Física, Espiritual ou Psicológica, que você julga afetarem a decisão
                                                vocacional, a capacidade de vida comunitária e/ou a finalidade
                                                missionária do seu formando? Se sim, partilhe um pouco sobre:</label>
                                            <input type="text" [(ngModel)]="resposta50.resposta" type="text"
                                                id="resposta50" name="resposta50" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">6. Como você percebe o processo de maturidade humana
                                                do seu formando (aspectos como autoconhecimento, controle emocional,
                                                temperamento, relacionamentos, afetividade, caminho de conversão,
                                                etc.)?</label>
                                            <input type="text" [(ngModel)]="resposta51.resposta" type="text"
                                                id="resposta51" name="resposta51" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">7. Seu formando faz uso de medicamentos que você
                                                acredita que possam interferir ou comprometer a vivência comunitária
                                                e/ou apostólica? Se sim, especifique quais.</label>
                                            <input type="text" [(ngModel)]="resposta52.resposta" type="text"
                                                id="resposta52" name="resposta52" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">8. Seu formando faz terapia?</label>
                                            <select class="form-control" [(ngModel)]="resposta53.resposta" type="text"
                                                id="resposta53" name="resposta53" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="resposta64.resposta && resposta64.resposta == '1'" class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Há quanto tempo? Você percebe que tem tido avanços no
                                                tratamento?</label>
                                            <input type="text" [(ngModel)]="resposta54.resposta" type="text"
                                                id="resposta54" name="resposta54" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">8.1 No que diz respeito à saúde psíquica (emocional):
                                                Como está sua saúde psicológica? Marque os sintomas abaixo, caso ele
                                                apresente algum deles:</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta55.resposta" id="resposta55"
                                                    name="resposta55">&nbsp; <label class="form-check-label">Crise de
                                                    ansiedade</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="resposta55.resposta" class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Frequência: </label>
                                            <input type="text" [(ngModel)]="resposta56.resposta" type="text"
                                                id="resposta56" name="resposta56" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta57.resposta" id="resposta57"
                                                    name="resposta57">&nbsp; <label class="form-check-label"
                                                    for="descricao">Desafios no sono</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta58.resposta" id="resposta58"
                                                    name="resposta58">&nbsp; <label class="form-check-label"
                                                    for="descricao">Choro frequente</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta59.resposta" id="resposta59"
                                                    name="resposta59">&nbsp; <label class="form-check-label"
                                                    for="descricao">Cansaço constante</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta60.resposta" id="resposta60"
                                                    name="resposta60">&nbsp; <label class="form-check-label"
                                                    for="descricao">Alto nível de estresse</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta61.resposta" id="resposta61"
                                                    name="resposta61">&nbsp; <label class="form-check-label"
                                                    for="descricao">Instabilidade de humor</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta62.resposta" id="resposta62"
                                                    name="resposta62">&nbsp; <label class="form-check-label"
                                                    for="descricao">Tendência ao isolamento</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta63.resposta" id="resposta63"
                                                    name="resposta63">&nbsp; <label class="form-check-label"
                                                    for="descricao">Pensamento depressivos</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete"><input type="checkbox"
                                                    [(ngModel)]="resposta64.resposta" id="resposta64"
                                                    name="resposta64">&nbsp; <label class="form-check-label"
                                                    for="descricao">Compulsão</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="resposta64.resposta" class="row">
                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="descricao">Qual? </label>
                                            <input type="text" [(ngModel)]="resposta65.resposta" type="text"
                                                id="resposta65" name="resposta65" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">9.a) Segundo a tua percepção, o teu formando é uma
                                                pessoa de comunhão?</label>
                                            <select class="form-control" [(ngModel)]="resposta66.resposta"
                                                id="resposta66" name="resposta66" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">b) Costuma divergir de pontos essenciais do
                                                Carisma?</label>
                                            <select class="form-control" [(ngModel)]="resposta67.resposta"
                                                id="resposta67" name="resposta67" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">c) Tem comunhão com o espírito do Fundador? </label>
                                            <select class="form-control" [(ngModel)]="resposta68.resposta"
                                                id="resposta68" name="resposta68" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Partilhe sobre o ponto 9:</label>
                                            <input type="text" [(ngModel)]="resposta69.resposta" id="resposta69"
                                                name="resposta69" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">10. Você acredita que o seu formando é aberto à
                                                dimensão missionária da comunidade?</label>
                                            <input type="text" [(ngModel)]="resposta70.resposta" id="resposta70"
                                                name="resposta70" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Ele já foi ou estaria disposto a ir em
                                                missão?</label>
                                            <input type="text" [(ngModel)]="resposta71.resposta" id="resposta71"
                                                name="resposta71" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">11. Seu formando é fiel à partilha da Comunhão de
                                                Bens?</label>
                                            <select class="form-control" [(ngModel)]="resposta72.resposta"
                                                id="resposta72" name="resposta72" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Ele partilha os 15%?</label>
                                            <select class="form-control" [(ngModel)]="resposta73.resposta"
                                                id="resposta73" name="resposta73" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">12. Existe algo que você gostaria de acrescentar que
                                                não foi abordado neste questionário? </label>
                                            <input type="text" [(ngModel)]="resposta74.resposta" id="resposta74"
                                                name="resposta74" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Diante do que foi escrito, seu parecer é: </label>
                                            <select class="form-control" [(ngModel)]="resposta75.resposta"
                                                id="resposta75" name="resposta75" required>
                                                <option disabled [value]="null" i18n>Selecione</option>
                                                <option [value]="1"
                                                    *ngIf="questionarioFormando.questionarioId == 6 || questionarioFormando.questionarioId == 7 || questionarioFormando.questionarioId == 8"
                                                    i18n>Mudança de Nível</option>
                                                <option [value]="2"
                                                    *ngIf="questionarioFormando.questionarioId == 4 || questionarioFormando.questionarioId == 6 || questionarioFormando.questionarioId == 7"
                                                    i18n>Permanecer no Nível</option>
                                                <option [value]="3" *ngIf="questionarioFormando.questionarioId == 4"
                                                    i18n>
                                                    Realizar Primeiras Promessas</option>
                                                <option [value]="4" *ngIf="questionarioFormando.questionarioId == 5"
                                                    i18n>
                                                    Realizar Promessas Definitivas</option>
                                                <option [value]="5" i18n>Desligamento CAL</option>
                                                <option [value]="6" i18n>Outro</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Justifique a sua resposta:</label>
                                            <input type="text" [(ngModel)]="resposta76.resposta" id="resposta76"
                                                name="resposta76" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button type="button" class="btn btn-default float-left"
                                        style="margin-left: 0.5rem;" (click)="activateTab('dados-pessoais-tab-content')"
                                        i18n>Voltar</button>&nbsp;
                                    <button type="button" (click)="salvarQuestionario()" class="btn btn-primary"
                                        i18n>Salvar</button>
                                    <button type="submit" (click)="enviarQuestionario()" class="btn btn-primary ml-1"
                                        [disabled]="questionarioForm.invalid" i18n>Enviar</button>
                                    <!-- <button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid"
                                        (click)="enviarQuestionario()" i18n>Enviar</button> -->
                                    <!--button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid || endereco.id == undefined" (click)="activateTab('endereco-tab-content')" i18n>Proximo</button-->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- /.modal-dialog -->
</secured>