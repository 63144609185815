import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { CacheService } from 'src/app/service/cache.service';

@Injectable()
export class PessoaService extends GenericService<Pessoa> {
    
    
    constructor(protected http: HttpClient, protected router:Router, private cacheService: CacheService) {
        super(http,router);
        this.setEntityType('r/pessoa');
    }
    
    atualizarFormaVida(request: any) {
        return this.http.post<any>(`${this.apiURL}/atualizarformavida`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    atualizarEstadoVida(request: any) {
        return this.http.post<any>(`${this.apiURL}/atualizarestadovida`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPessoas(busca: any) {
        const cacheKey = 'buscarPessoas-' + JSON.stringify(busca);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    buscarMembrosCasaComunitaria(busca: any) {
        const cacheKey = 'buscarMembrosCasaComunitaria-' + JSON.stringify(busca);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.post<any>(this.apiURL+'/buscarmembroscasacomunitaria', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    pesquisarPessoasInfoListagem (busca: any) {
        const cacheKey = 'pesquisarPessoasInfoListagem-' + JSON.stringify(busca);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.post<any>(this.apiURL+'/pesquisarpessoainfolistagem', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    buscaPorUsuarioId(id: number): Promise<any> {
        const cacheKey = 'buscaPorUsuarioId-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(`${this.apiURL}/porusuario/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    buscaPorId(id: number): Promise<any> {
        const cacheKey = 'buscaPorId-' + JSON.stringify(id);

        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
          return Promise.resolve(this.cacheService.get(cacheKey));
        }
    
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http.get<any>(`${this.apiURL}/${id}`, { 'headers' : this.getHeaders() }).toPromise().then(response => {
            this.cacheService.set(cacheKey, response);  // Armazena no cache
            return response;
          });
    }

    buscaPessoaPorUsuarioCriador(): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porusuariocriador`, { 'headers' : this.getHeaders() }).toPromise();
    }

    acompanhadoresGrupoOracao(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/acompanhadoresgrupooracao/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    setarRegistroDuplicado(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/registroduplicado/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

} 