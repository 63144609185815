import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { Usuario } from "src/app/model/usuario.model";
import { UsuarioService } from "../../usuario/service/usuario.service";
import { ImagemService } from "src/app/secured/service/imagem.service";
import { PessoaService } from "../../pessoa/service/pessoa.service";
import { MembroComunidadeService } from "src/app/secured/service/membro.comunidade.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Celula } from "src/app/model/celula.model";
import { CelulaService } from "../../celula/service/celula.service";
import { QuestionarioFormandoService } from "../service/questionarioformando.service";
import { FormadorComunitario } from "src/app/model/formadorcomunitario.model";
import { FormadorComunitarioService } from "src/app/secured/service/formador.comunitario.service";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { ElementoGrupoDominioService } from "src/app/secured/service/elementogrupodominio.service";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { ParecerResponsavelBuilder } from "src/app/builder/parecerresponsavel.builder";
import { ParecerResponsavelService } from "../../parecer/service/parecerresponsavel.service";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ArquivoService } from "src/app/secured/service/arquivo.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { CaminhoFormativoService } from "../service/caminhoformativo.service";

@Component({
  selector: "app-questionario-celula",
  templateUrl: "./questionario-celula.component.html",
  styleUrls: ["./questionario-celula.component.css"],
})
export class QuestionarioCelulaComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<null>();

  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public questionariosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;
  public niveisFormacao: Array<ElementoGrupoDominio>;

  public totalDePaginas: number = 1;

  public questionarioId: number;
  public questionarioFormandoSelectedId: number;
  public pedidoFormandoId: number;
  public buscaMembros: any;
  public buscaQuestionarioFormando: any;
  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public caminhoFormativo: CaminhoFormativo;

  public idsPPs = [25];
  public idsPDs = [30, 31, 108, 204, 205];
  public idsD1s = [24];
  public idsPostulantes = [22, 23];
  public idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];

  selectedFile: File | null = null;
  errorMessage: string = "";
  isFormValidUpload: boolean = false;

  public sanitizedPdfUrl: SafeResourceUrl;
  @ViewChild("pdfViewer", { static: false })
  pdfViewer: ElementRef;

  //questionarioConfiguracao: QuestionarioConfiguracao;

  constructor(
    //private missaoConfiguracaoService: MissaoConfiguracaoService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public formadorComunitarioService: FormadorComunitarioService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public caminhoFormativoService: CaminhoFormativoService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.questionariosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
    this.buscaMembros = {
      celulaId: 0,
      pageNumber: 0,
      quantityOfElements: 0,
    };
    this.buscaQuestionarioFormando = {
      questionarioId: null,
      casaCelulaId: null,
      pessoaId: null,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async initializer(): Promise<any> {
    this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
    this.carregarNivelFormacao();
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadorComunitario();
    if (
      this.formadorComunitario != undefined &&
      this.formadorComunitario.casaCelulaId != null
    ) {
      await this.carregarCelula();
      this.buscaMembros = {
        celulaId: this.celula.id,
        pageNumber: 0,
        quantityOfElements: 4,
      };
      this.buscaQuestionarioFormando = {
        questionarioId: this.questionarioId,
        casaCelulaId: this.celula.id,
        pessoaId: null,
      };
      await this.carregarMembrosCelula();
    } else this.hasCelulaAssociada = false;
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /*carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }*/

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;

      /*if(this.pessoa.imagemId != null)
        {
            let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

            this.imagemService.createImage(blogImage, this.pessoa);
        } */
    } catch (e) {
      console.log(e);
    }
  }

  async carregarFormadorComunitario(): Promise<any> {
    try {
      let response =
        await this.formadorComunitarioService.buscarCelulaPorPessoaId(
          this.pessoa.id
        );
      this.formadorComunitario = response;

      if (this.formadorComunitario.pessoaImagemId != null) {
        let blogImage = await this.getPessoaImagem(
          this.formadorComunitario.pessoaImagemId
        );

        this.imagemService.createImage(blogImage, this.formadorComunitario);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async carregarCelula(): Promise<any> {
    try {
      let response = await this.celulaService.find(
        this.formadorComunitario.casaCelulaId
      );
      this.celula = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarQuestionarioCelula(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorCelulaId(
        this.buscaQuestionarioFormando
      );
      this.questionariosCelula = response;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      let idsBusca = [];
      switch (this.questionarioId) {
        case 4:
          idsBusca = this.idsPPs;
          break;
        case 5:
          idsBusca = this.idsPDs;
          break;
        case 6:
          idsBusca = this.idsPostulantes;
          break;
        case 7:
          idsBusca = this.idsD1s;
          break;
        case 8:
          idsBusca = this.idsRenovacao;
          break;
        default:
          idsBusca = null;
          break;
      }
      if (idsBusca != null) {
        let response = await this.membroService.listarMembrosCelulaPorNivel(
          this.celula.id,
          idsBusca,
          this.buscaMembros.pageNumber
        );

        this.membrosCelula = response.content;
        this.totalDePaginasMembros = response.totalPages;
      } else {
        let response = await this.membroService.listarTodosMembrosCelula(
          this.celula.id
        );
        this.membrosCelula = response.content;
        this.totalDePaginasMembros = response.totalPages;
      }

      await this.carregarQuestionarioCelula();

      /*if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }*/

      for (let i = 0; i < this.membrosCelula.length; i++) {
        let response = await this.pessoaService.buscaPorId(
          this.membrosCelula[i].pessoaId
        );
        let pessoa = response.entity;
        this.membrosCelula[i].pessoaNome = pessoa.nome + " " + pessoa.sobrenome;
        this.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
        if (this.membrosCelula[i].pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCelula[i].pessoaImagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCelula[i]);
        }

        let nivelFormativoMembro = this.niveisFormacao.find(
          (obj) => obj.id === this.membrosCelula[i].nivelFormativoId
        );
        this.membrosCelula[i].nivelFormativoNome = nivelFormativoMembro
          ? nivelFormativoMembro.nome
          : null;

        const questionario = this.questionariosCelula.filter(
          (questionarioFormando) =>
            questionarioFormando.id === this.membrosCelula[i].id
        );

        if (questionario.length > 0) {
          this.membrosCelula[i].statusQuestionario =
            questionario[0].status_questionario;
          this.membrosCelula[i].idQuestionarioFormando =
            questionario[0].questionarioFormandoId;
          this.membrosCelula[i].idQuestionario = questionario[0].questionarioId;
          this.membrosCelula[i].statusParecer = questionario[0].status_parecer;
          this.membrosCelula[i].idParecer = questionario[0].parecerId;
          this.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
          this.membrosCelula[i].acompanhadoPorNome =
            questionario[0].acompanhadoPorNome;
          this.membrosCelula[i].arquivo = questionario[0].arquivo;
          this.membrosCelula[i].dataEnvioParecer =
            questionario[0].dataEnvioParecer;
          this.membrosCelula[i].dataEnvioQuestionario =
            questionario[0].dataEnvioQuestionario;
          this.membrosCelula[i].missaoAtualId = questionario[0].missaoAtualId;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  /*async carregarQuestionario(pessoaId: number): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(
        pessoaId
      );
      if (response != undefined && response != null) {
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecer(idQuestionario: number): Promise<any> {
    try {
      let response =
        await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
          idQuestionario
        );
      return response;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }*/

  async consultarCaminhoFormativo(formando: any): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        formando.pessoaId
      );
      if (response != null && response != undefined) {
        this.caminhoFormativo = response;
      } else {
        this.caminhoFormativo = {} as CaminhoFormativo;
        this.caminhoFormativo.pessoaId = formando.pessoaId;
        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
        this.caminhoFormativo.ano_formativo_id = 2;
        this.caminhoFormativo.nome_formador_comunitario =
          this.formadorComunitario.pessoaNome;
        let response = await this.caminhoFormativoService.cadastrar(
          this.caminhoFormativo
        );

        this.caminhoFormativo = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      await this.consultarCaminhoFormativo(formando);
      let responsePorPessoa =
        await this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(
          formando.pessoaId,
          this.questionarioId
        );
      if (
        responsePorPessoa != undefined &&
        responsePorPessoa != null &&
        responsePorPessoa.questionarioId == this.questionarioId
      ) {
        this.questionarioFormando = responsePorPessoa;
        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
        //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
      } else {
        this.questionarioFormando.anoFormativoId = 2;
        this.questionarioFormando.questionarioId = this.questionarioId;
        this.questionarioFormando.pessoaId = formando.pessoaId;
        let response = await this.questionarioFormandoService.cadastrar(
          this.questionarioFormando
        );
        if (response != undefined && response != null) {
          this.questionarioFormando = response;
          this.questionarioFormandoSelectedId = this.questionarioFormando.id;
          formando.idQuestionarioFormando = this.questionarioFormando.id;
          //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoParecerCAL(
    questionarioFormandoId: number
  ): Promise<any> {
    try {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 3;
      this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
      let response = await this.parecerResponsavelService.cadastrar(
        this.parecerResponsavel
      );
      if (response != undefined && response != null) {
        this.parecerResponsavel = response;
        this.router.navigate([
          "/secured/parecer-cal-answer-default" +
            "/" +
            this.parecerResponsavel.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async consultarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      if (
        formando.idQuestionarioFormando != undefined &&
        formando.idQuestionarioFormando != null
      ) {
        if (formando.idParecer != undefined && formando.idParecer != null) {
          this.router.navigate([
            "/secured/parecer-cal-answer-default" + "/" + formando.idParecer,
          ]);
        } else {
          this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
        }
      } else if (
        formando.idQuestionarioFormando != undefined ||
        formando.idQuestionarioFormando == null
      ) {
        await this.cadastrarVinculoQuestionarioCAL(formando);
        await this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarNivelFormacao(): Promise<any> {
    let request = {
      grupoNome: "NIVEL_FORMACAO",
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.niveisFormacao = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async configModalUploadQuestionario(formando: any): Promise<any> {
    if (
      formando.questionarioFormandoId != undefined &&
      formando.questionarioFormandoId != null
    ) {
      this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
    } else {
      await this.cadastrarVinculoQuestionarioCAL(formando);
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 3 * 1024 * 1024
    ) {
      this.selectedFile = file;
      this.errorMessage = "";
    } else {
      this.selectedFile = null;
      this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
    }
    this.checkFormUploadValidity();
  }

  onUpload() {
    if (this.selectedFile && this.pedidoFormandoId) {
      this.questionarioFormandoService
        .uploadQuestionario(
          this.questionarioFormandoSelectedId,
          this.pedidoFormandoId,
          this.selectedFile
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            this.swtAlert2Service.successAlert(data);
            this.carregarMembrosCelula();
            $("#modal-upload-questionario").modal("hide");
          },
          error: (error) => {
            this.swtAlert2Service.errorAlert(
              "Erro ao realizar o upload do arquivo."
            );
          },
          complete: () => {
            this.unsubscribe$.next();
          },
        });
      this.pedidoFormandoId = null;
    } else {
      if (!this.pedidoFormandoId) {
        this.errorMessage =
          "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
      } else {
        this.errorMessage =
          "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
      }
    }
  }

  onTipoPedidoChange() {
    this.checkFormUploadValidity();
  }

  // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
  checkFormUploadValidity() {
    this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
  }

  cliqueNoBotaoListener(botao) {
    this.buscaMembros.pageNumber = botao.numero - 1;
    this.carregarMembrosCelula();
  }
}
