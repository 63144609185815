<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!-- <div class="row">
                    <div class="col-12">
                        Não há nenhum questionário disponível para informar parecer.
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="6"
                                        [rows]="paginacaoConfig.totalElements"
                                        [totalDePaginas]="paginacaoConfig.totalPages"
                                        (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Celula</th>
                                            <th>Nível Formativo</th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Status
                                                Questionario</th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Parecer
                                            </th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">Feedback</th>
                                        </tr>
                                        <tr tablebody *ngFor="let formador of formadoresDaMissao">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="formador.thumbnail == null ? 'assets/images/ppl.png' : formador.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{formador.pessoaNome}}</td>
                                            <td>{{formador.celulaNome}}</td>
                                            <td>{{formador.nivelFormativoNome}}</td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button *ngIf="formador.questionarioFormandoStatus == 'ENVIADO'"
                                                    class="btn btn-primary"
                                                    routerLink="/secured/questionariocal-view-answer/{{formador.questionarioFormandoId}}"
                                                    title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="formador.questionarioFormandoStatus != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Questionario CAL"
                                                    alt="Questionario CAL">{{formador.questionarioFormandoStatus}}</button>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button
                                                    *ngIf="formador.parecerStatus == 'ENVIADO' && formador.usuarioLogadoPodeDarParecer; else blocoElseIf"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">{{formador.parecerStatus}}</button>
                                                <ng-template #blocoElseIf>
                                                    <button
                                                        *ngIf="formador.parecerStatus != 'ENVIADO' && formador.usuarioLogadoPodeDarParecer; else mensagemParecerOutraAutoridade"
                                                        class="btn btn-primary"
                                                        (click)="consultarVinculoParecerCAL(formador)"
                                                        title="Parecer CAL" alt="Parecer CAL">Responder</button>
                                                </ng-template>
                                                <ng-template #mensagemParecerOutraAutoridade>
                                                    Acompanhado(a) por outra autoridade
                                                </ng-template>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">
                                                {{formador.feedbackCaminhoFormativo ? formador.feedbackCaminhoFormativo
                                                :
                                                'Feedback não informado'}}
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>