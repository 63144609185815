import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormadorComunitario } from "src/app/model/formadorcomunitario.model";
import { FormadorAssistente } from "src/app/model/formadorassistente.model";
var CelulaManageComponent = /** @class */ (function () {
    function CelulaManageComponent(celulaService, celulaBuilder, membroComunidadeBuilder, membroService, formadorComunitarioService, imagemService, route, router, swtAlert2Service) {
        this.celulaService = celulaService;
        this.celulaBuilder = celulaBuilder;
        this.membroComunidadeBuilder = membroComunidadeBuilder;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.imagemService = imagemService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginasFormadoresComunitarios = 0;
        this.totalDePaginasFormadoresAssistentes = 0;
        this.totalDePaginasMembros = 0;
        this.centrosEvangelizacao = new Array();
        this.fases = new Array();
        this.celula = this.celulaBuilder.getInstance();
        this.membrosCelula = new Array();
        this.formadoresComunitarios = new Array();
    }
    CelulaManageComponent.prototype.ngOnInit = function () {
        this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
        this.formadorComunitario = this.novoFormadorComunitarioCelula();
        this.formadorAssistente = this.novoFormadorAssistenteCelula();
        this.carregarCelula();
        this.buscaMembros = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarMembrosCelula();
        this.buscaFormadoresComunitarios = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarFormadoresComunitarios();
        this.buscaFormadoresAssistentes = {
            celulaId: this.celulaId,
            pageNumber: 0,
            quantityOfElements: 100,
        };
        this.carregarFormadoresAssistentes();
    };
    /**
     * Formador Comunitário
     */
    CelulaManageComponent.prototype.cliqueNoBotaoFormadoresComunitariosListener = function (botao) {
        this.buscaFormadoresComunitarios.pageNumber = botao.numero - 1;
        this.buscaFormadoresComunitarios();
    };
    CelulaManageComponent.prototype.novoFormadorComunitarioCelula = function () {
        return new FormadorComunitario(null, 2, this.celulaId, null, null, null, null, null, null, null, null, false);
    };
    CelulaManageComponent.prototype.salvarFormadorComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!(this.formadorComunitario.pessoaId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.celulaService.cadastrarFormadorComunitario(this.formadorComunitario)];
                    case 1:
                        response = _a.sent();
                        this.formadorComunitario = this.novoFormadorComunitarioCelula();
                        this.pessoaFormadorComunitarioSelecionada = undefined;
                        this.carregarFormadoresComunitarios();
                        this.swtAlert2Service.successAlert("Formador comunitário cadastrado com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador comunitário.");
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_1.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.carregarFormadoresComunitarios = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.celulaService.listarFormadorComunitarioPorCelulaId(this.celulaId)];
                    case 1:
                        response = _a.sent();
                        this.formadoresComunitarios = response;
                        this.totalDePaginasFormadoresComunitarios = 1;
                        //this.formadoresComunitarios = response.content;
                        //this.totalDePaginasFormadoresComunitarios = response.totalPages;
                        if (this.formadoresComunitarios.length > 0) {
                            this.formadoresComunitarios = this.formadoresComunitarios.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.formadoresComunitarios.length)) return [3 /*break*/, 5];
                        if (!(this.formadoresComunitarios[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresComunitarios[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadoresComunitarios[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.atualizarFormador = function (formador) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.atualizarFormadorComunitario(formador)];
                    case 1:
                        response = _a.sent();
                        this.carregarFormadoresComunitarios();
                        this.swtAlert2Service.successAlert("Formador Comunitário atualizado com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_2.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.removerFormador = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!confirm("Deseja deletar esse formador?")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.celulaService.deletarFormadorComunitario(id)];
                    case 1:
                        response = _a.sent();
                        this.carregarFormadoresComunitarios();
                        this.swtAlert2Service.successAlert("Formador Comunitário removido com sucesso!");
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_3.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.selecionarFormadorComunitario = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formadorComunitario.pessoaId = pessoa.id;
                this.pessoaFormadorComunitarioSelecionada = pessoa;
                $("#listagem-pessoa-formador-comunitario").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    /**
     * Formador Assistente
     */
    CelulaManageComponent.prototype.cliqueNoBotaoFormadoresAssistentesListener = function (botao) {
        this.buscaFormadoresAssistentes.pageNumber = botao.numero - 1;
        this.buscaFormadoresAssistentes();
    };
    CelulaManageComponent.prototype.novoFormadorAssistenteCelula = function () {
        return new FormadorAssistente(null, 2, this.celulaId, null, null, null, null, null, null, null, null, false);
    };
    CelulaManageComponent.prototype.salvarFormadorAssistente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!(this.formadorAssistente.pessoaId != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.celulaService.cadastrarFormadorAssistente(this.formadorAssistente)];
                    case 1:
                        response = _a.sent();
                        this.formadorAssistente = this.novoFormadorAssistenteCelula();
                        this.pessoaFormadorAssistenteSelecionada = undefined;
                        this.carregarFormadoresAssistentes();
                        this.swtAlert2Service.successAlert("Formador Assistente cadastrado com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        this.swtAlert2Service.warningAlert("Selecione a pessoa para vincular como formador assistente.");
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_4.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.carregarFormadoresAssistentes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.celulaService.listarFormadorAssistentePorCelulaId(this.celulaId)];
                    case 1:
                        response = _a.sent();
                        this.formadoresAssistentes = response;
                        this.totalDePaginasMembros = 1;
                        //this.formadoresAssistentes = response.content;
                        //this.totalDePaginasFormadoresAssistentes = response.totalPages;
                        if (this.formadoresAssistentes.length > 0) {
                            this.formadoresAssistentes = this.formadoresAssistentes.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.formadoresAssistentes.length)) return [3 /*break*/, 5];
                        if (!(this.formadoresAssistentes[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadoresAssistentes[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadoresAssistentes[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.atualizarFormadorAssistente = function (formador) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.atualizarFormadorAssistente(formador)];
                    case 1:
                        response = _a.sent();
                        this.carregarFormadoresAssistentes();
                        this.swtAlert2Service.successAlert("Formador Assistente atualizado com sucesso!");
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.removerFormadorAssistente = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!confirm("Deseja deletar esse formador?")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.celulaService.deletarFormadorAssistente(id)];
                    case 1:
                        response = _a.sent();
                        this.carregarFormadoresAssistentes();
                        this.swtAlert2Service.successAlert("Formador Assistente removido com sucesso!");
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_6.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.selecionarFormadorAssistente = function (pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formadorAssistente.pessoaId = pessoa.id;
                this.pessoaFormadorAssistenteSelecionada = pessoa;
                $("#listagem-pessoa-formador-assistente").modal("hide");
                return [2 /*return*/];
            });
        });
    };
    /**
     * Formador Célula
     */
    CelulaManageComponent.prototype.carregarCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.celulaService.find(this.celulaId)];
                    case 1:
                        response = _a.sent();
                        this.celula = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(e_7.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.cliqueNoBotaoMembrosListener = function (botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.buscaMembros();
    };
    CelulaManageComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, i, blogImage, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.membroService.listarMembrosCelula(this.celulaId)];
                    case 1:
                        response = _a.sent();
                        this.membrosCelula = response;
                        this.totalDePaginasMembros = 1;
                        //this.membrosCelula = response.content;
                        //this.totalDePaginasMembros = response.totalPages;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.membrosCelula.length)) return [3 /*break*/, 5];
                        if (!(this.membrosCelula[i].pessoaImagemId != null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId)];
                    case 3:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CelulaManageComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return CelulaManageComponent;
}());
export { CelulaManageComponent };
