import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
var MembroComunidadeService = /** @class */ (function (_super) {
    tslib_1.__extends(MembroComunidadeService, _super);
    function MembroComunidadeService(http, router, cacheService) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.setEntityType("membrocomunidade");
        return _this;
    }
    MembroComunidadeService.prototype.buscarPorCelula = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao +
            "membrocomunidade/porcelula", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.buscarPorCasaComunitaria = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao +
            "membrocomunidade/porcasacomunitaria", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.listarMembrosCelula = function (id) {
        var _this = this;
        var cacheKey = "listarMembrosCelula-" + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/celula/porid/" + id, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    MembroComunidadeService.prototype.listarMembrosCasaComunitaria = function (id) {
        return this.http
            .get(this.apiURL + "membrocomunidade/porcasacomunitaria/" + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    MembroComunidadeService.prototype.listarTodosMembrosCelula = function (id) {
        var _this = this;
        var cacheKey = "listarTodosMembrosCelula-" + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/celula/allporid/" + id, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    MembroComunidadeService.prototype.listarMembrosCelulaPorNivel = function (id, nivelFormativoIds, page) {
        var _this = this;
        var cacheKey = "listarMembrosCelulaPorNivel-" +
            JSON.stringify(id) +
            JSON.stringify(nivelFormativoIds) +
            "?page=" +
            page;
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/celula/poridenivel/" + id + "/n/" + nivelFormativoIds, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    MembroComunidadeService.prototype.listarMembrosCasaComunitariaPorNivel = function (id, nivelFormativoIds) {
        var _this = this;
        var cacheKey = "listarMembrosCasaComunitariaPorNivel-" +
            JSON.stringify(id) +
            JSON.stringify(nivelFormativoIds);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/casacomunitaria/poridenivel/" + id + "/n/" + nivelFormativoIds, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    MembroComunidadeService.prototype.listarTodosMembrosCasaComunitaria = function (id) {
        var _this = this;
        var cacheKey = "listarTodosMembrosCelula-" + JSON.stringify(id);
        // Verifica se os dados já estão no cache
        if (this.cacheService.has(cacheKey)) {
            return Promise.resolve(this.cacheService.get(cacheKey));
        }
        // Caso não tenha no cache, faz a requisição HTTP
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "membrocomunidade/casacomunitaria/allporid/" + id, { headers: this.getHeaders() })
            .toPromise()
            .then(function (response) {
            _this.cacheService.set(cacheKey, response); // Armazena no cache
            return response;
        });
    };
    MembroComunidadeService.prototype.vincularMembroCelula = function (request) {
        return this.http
            .post(this.apiURL + "/vincularmembrocelula", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.vincularMembroCasaComunitaria = function (request) {
        return this.http
            .post(this.apiURL + "/vincularmembrocasacomunitaria", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.desvincularMembroCelula = function (request) {
        return this.http
            .post(this.apiURL + "/desvincularmembrocelula", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.desvincularMembroCasaComunitaria = function (request) {
        return this.http
            .post(this.apiURL + "/desvincularmembrocasacomunitaria", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    MembroComunidadeService.prototype.detalhesMembroCelula = function (request) {
        return this.http
            .post(this.apiURL + "/detalhesmembro", JSON.stringify(request), {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    MembroComunidadeService.prototype.detalhesMembroCasaComunitaria = function (request) {
        return this.http
            .post(this.apiURL + "/detalhesmembro", JSON.stringify(request), {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    MembroComunidadeService.prototype.buscarPorPessoaId = function (id) {
        return this.http
            .get(this.apiURL + "/porpessoa/" + id, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    MembroComunidadeService.prototype.aniversariantesCelula = function (celulaId) {
        return this.http
            .get(this.apiURL + "/celula/aniversariantes/" + celulaId, {
            headers: this.getHeaders(),
        })
            .toPromise();
    };
    MembroComunidadeService.prototype.aniversariantesCasaComunitaria = function (casaComunitariaId) {
        return this.http
            .get(this.apiURL + "/casacomunitaria/aniversariantes/" + casaComunitariaId, { headers: this.getHeaders() })
            .toPromise();
    };
    return MembroComunidadeService;
}(GenericService));
export { MembroComunidadeService };
